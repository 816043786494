import React, {useEffect} from 'react';
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";
import {jumpToTop, MAX_MOBILE_WIDTH, UseWindowSize} from "../../helper/util";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import EditTextComponent from "../../components/editText/EditTextComponent";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";
import ContactPage from "../contact/Contact";
import SupportCard from "../../components/supportCard/SupportCard";

const namespace = 'donate'
const DonatePage = () => {
    let applicationWidth = UseWindowSize().width;

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);

    return (
        <div className={'grid ui'} style={{padding: 0, background: '#fbfcf7'}}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Ehrenamt', url: '/ehrenamt'}]}/>
            </div>
            <div
                className={'row top-image-cut'}
                style={{backgroundImage: "url(" + config.BASE_URL_IMAGES + "StiftungTopImage.jpg)"}}>
                <img src={'/images/bows/Bogen6_Beige.svg'} alt={'bogen'}
                     className={'bow-6-position'}/>
            </div>
            <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide column stiftung-card-container'}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position stiftung-card-position'}>
                            <Card.Header className={'card-header'} s>
                                <h1><EditTextComponent id={`${namespace}-card-header-1`} namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-text-1`} namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-header-1`} namespace={namespace}/></h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-text-1`} namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered stiftung-colored-section page-content-wrapper'}>
                <div className={'fourteen wide computer twelve wide mobile column margin-top-bottom'}
                     style={{paddingTop: '4rem'}}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <p>
                        <EditHTMLComponent id={`${namespace}-text-2`} namespace={namespace}/>
                    </p>
                </div>

                <div className={'fourteen wide column'}>
                    {/*      <iframe       src="https://www.helpmundo.de/helptools/donationsv2/15623/0/?df_paragraph_text_color=&amp;df_secondary_text_color=&amp;df_active_bg_color=0071A3&amp;df_active_text_color=ffffff&amp;df_bg_color=&fbfcf7;df_font_type=lato&amp;_=1576655726762"
                        frameBorder="0" scrolling="no" id="iframe-donationform-default"
                        style={{"width": "100%", "height": "1600px", "overflow": "hidden"}}/>*/}


                    <button className={'button-1'} onClick={_=>{
                        if(typeof window !== "undefined"){
                            window.open(config.URL+'downloads/Hospiz_Mitglied_Formular.pdf','_newtab');
                        }
                    }}>MITGLIED WERDEN ></button>
                </div>
                <div className={'fourteen wide column '} style={{minHeight:'500px'}}>
                    <ContactPage type={'stationaer'}/>
                </div>
            </div>
            <div className={'row centered'} style={{background: '#7C962E'}}>
                <img src={'/images/bows/Bogen4_beige.svg'} alt={'bogen'}
                     className={'bow-4-position'}/>
                <SupportCard/>
            </div>
        </div>
    )
}

DonatePage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'donate').then((text) => {
        return text;
    })
}

export default DonatePage;
